import React, { useContext, useEffect, useState } from "react";
import LoginPageImage from "../../Assets/Reference/LoginPageImage.png";
import "./Login.css";
import "./createAccount.css";
import LoginPageBG from "../../Assets/Login/LoginPageBG.jpg";
import login from "./loginApi";
import LoginUseReducer from "./LoginUseReducer";
import { apiCall, setTokenInHeaders, setApiToken } from "../../Utils/Network";
import { login_url, send_email_otp, verify_email_otp, send_otp, state_list, city_list, clg_list, sign_up, verify_otp } from "../../Utils/ApiUrls";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";
import { Link, withRouter } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Select } from 'antd';

// const initialState = {
//     username: 'kanojiask62@gmail.com',
//     password: 'b@123',
//     isLoading: false,
//     error: '',
//     isLoggedIn: false,
// };

function CreateAccount(props) {
  const { set_user_data, user_details } = useContext(UserCredsContext);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    // email_otp: "",
    email_verify_otp: 3399,
    mobile_number: "",
    mobile_otp: "",
    password: "",
    state_id: "",
    city_id: "",
    college_id: "",
    college_year: "",
    website_source: 'TESTPREP'
  });
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [collegeList, setCollegeList] = useState([]);
  const [otpSentEmail, setOtpSentEmail] = useState(false);
  const [otpSentMob, setOtpSentMob] = useState(false);
  const [toast, setToast] = useState({ msg: "", type: "" });
  const { Option } = Select;

  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  useEffect(() => {
    if (toast.type) setTimeout(() => setToast({ msg: "", type: "" }), 3000);
  }, [toast]);

  useEffect(() => {
    getStateListHandler();
    getCollegeListHandler();
  }, []);

  // useEffect(() => {
  //   if (data.email && data.mobile_number.length === 10) sendOtpHandler();
  // }, [data.mobile_number, data.email]);

  // const sendEmailOtpHandler = (e) => {
  //   e.preventDefault();

  //   if (otpSentEmail) return;

  //   const payload = { email: data.email, website_source: 'TESTPREP' };
  //   apiCall.post(send_email_otp, payload)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setOtpSentEmail(true);
  //         setToast({ msg: response.data.data, type: "success" });
  //       } else {
  //         throw new Error(response.data.data || "Failed to send OTP");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setOtpSentEmail(false);
  //       setToast({ msg: error.response?.data?.message || "Something went wrong!", type: "error" });
  //     });
  // };

  // const verifyEmailOtpHandler = (e) => {
  //   e.preventDefault();

  //   const payload = { email: data.email, email_otp: data.email_otp, website_source: 'TESTPREP' };
  //   apiCall.post(verify_email_otp, payload)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setOtpSentEmail(true);
  //         setToast({ msg: response.data.data, type: "success" });
  //       } else {
  //         throw new Error(response.data.data || "Failed to send OTP");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setOtpSentEmail(false);
  //       setToast({ msg: error.response?.data?.message || "Something went wrong!", type: "error" });
  //     });
  // };

  const sendOtpHandler = (e) => {
    e.preventDefault();

    if (otpSentMob) return;

    // if (parseInt(data.mobile_number) < 1000000000) {
    //   setToast({
    //     msg: "Please Enter a Valid Mobile Number",
    //     type: "error",
    //   });
    //   return;
    // }

    const payload = { mobile_number: data.mobile_number, website_source: 'TESTPREP' };
    apiCall.post(send_otp, payload)
      .then((response) => {
        if (response.status === 200) {
          setOtpSentMob(true);
          setToast({ msg: response.data.data, type: "success" });
        } else {
          throw new Error(response.data.data || "Failed to send OTP");
        }
      })
      .catch((error) => {
        console.error(error);
        setOtpSentMob(false);
        setToast({ msg: error.response?.data?.message || "Something went wrong!", type: "error" });
      });
  };

  const verifyMobileOtpHandler = (e) => {
    e.preventDefault();

    const payload = { mobile_number: data.mobile_number, mobile_otp: data.mobile_otp, website_source: 'TESTPREP' };
    apiCall.post(verify_otp, payload)
      .then((response) => {
        if (response.status === 200) {
          setOtpSentMob(true);
          setToast({ msg: response.data.data, type: "success" });
        } else {
          throw new Error(response.data.data || "Failed to send OTP");
        }
      })
      .catch((error) => {
        console.error(error);
        setOtpSentMob(false);
        setToast({ msg: error.response?.data?.message || "Something went wrong!", type: "error" });
      });
  };

  const getStateListHandler = (e) => {
    apiCall.get(state_list)
      .then((response) => {
        if (response.status === 200) {
          setStateList(response.data.data);
          // console.log(setStateList)
        } else {
          throw new Error(response.data.data || "Failed to send OTP");
        }
      })
      .catch((error) => {
        console.error(error);
        setToast({ msg: error.response?.data?.message || "Something went wrong!", type: "error" });
      });
  };

  const getCityListHandler = (stateId) => {
    apiCall.get(city_list(stateId))
      .then((response) => {
        if (response.status === 200) {
          setCityList(response.data.data);
          // console.log(setCityList)
        } else {
          throw new Error(response.data.data || "Failed to send OTP");
        }
      })
      .catch((error) => {
        console.error(error);
        setToast({ msg: error.response?.data?.message || "Something went wrong!", type: "error" });
      });
  };

  const getCollegeListHandler = () => {
    apiCall.get(clg_list)
      .then((response) => {
        if (response.status === 200) {
          setCollegeList(response.data.data)
          // console.log(setCollegeList)
        } else {
          throw new Error(response.data.data || "Failed to send OTP");
        }
      })
      .catch((error) => {
        console.error(error);
        setToast({ msg: error.response?.data?.message || "Something went wrong!", type: "error" });
      });
  };

  const registerHandler = async (event) => {
    event.preventDefault();
    if (!data.first_name)
      return setToast({ msg: "Please Enter First Name", type: "error" });
    else if (!data.last_name)
      return setToast({ msg: "Please Enter Last Name", type: "error" });
    else if (!data.email)
      return setToast({ msg: "Please Enter Email", type: "error" });
    // else if (!data.email_otp)
    //   return setToast({ msg: "Please Enter Email OTP", type: "error" });
    else if (!data.mobile_number)
      return setToast({ msg: "Please Enter Mobile Number", type: "error" });
    else if (data.mobile_number.length !== 10)
      return setToast({
        msg: "Please Enter Valid Mobile Number",
        type: "error",
      });
    else if (!data.mobile_otp)
      return setToast({ msg: "Please Enter Mobile OTP", type: "error" });
    else if (!data.password)
      return setToast({ msg: "Please Enter Password", type: "error" });

    apiCall.post(sign_up, data)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setToast({ msg: response.data.data, type: "success" });

          const { access_token, login_object } = response.data.data;
          set_user_data(login_object, access_token);
          localStorage.setItem("network_access_token", access_token);
          setTokenInHeaders(access_token);
          setApiToken(access_token);

          setTimeout(() => props.history.push("/dashboard"), 1000);
        } else {
          setOtpSentEmail(false);
          setOtpSentMob(false);
          setToast({ msg: response.data.data, type: "error" });
        }
      })
      .catch((error) => {
        console.log(error);
        setOtpSentEmail(false);
        setOtpSentMob(false);
        setToast({
          msg: error.response?.data?.message || "Something went wrong !",
          type: "error",
        });
      });
  };

  return (
    <div
      className=" loginContainer heroLogin"
      style={{ background: `url(${LoginPageBG})` }}
    >
      <div id="snackbar" className={toast.type ? toast.type + " show" : ""}>
        {toast.msg}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 login-sec createBox">
            <h2 className="text-center">USER REGISTRATION</h2>

            <form id="login" className="validate">
              <div className="form-group inp-group">
                <input
                  type="text"
                  placeholder="First Name"
                  name="fname"
                  className=""
                  value={data.first_name}
                  onChange={(e) =>
                    setData({ ...data, first_name: e.target.value })
                  }
                />

                {/* <small className="help-block">{usernameError}</small> */}
              </div>
              <div className="form-group inp-group">
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lname"
                  className=""
                  value={data.last_name}
                  onChange={(e) =>
                    setData({ ...data, last_name: e.target.value })
                  }
                />

                {/* <small className="help-block">{usernameError}</small> */}
              </div>
              <div className="form-group inp-group">
                <input
                  type="text"
                  placeholder="Email Id"
                  name="email"
                  className=""
                  value={data.email}
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                />

                {/* <div className="input-n-btn">
                  <input
                    type="text"
                    placeholder="Email Id"
                    name="email"
                    className=""
                    value={data.email}
                    onChange={(e) => setData({ ...data, email: e.target.value })}
                  />
                  <button className="verify-btn" onClick={sendEmailOtpHandler}>Send OTP</button>

                </div> */}

                {/* <div className="input-n-btn">
                  <input
                    type="Number"
                    placeholder="Email OTP"
                    name="verifyEmailOtp"
                    className="px-3"
                    value={data.email_otp}
                    onChange={(e) =>
                      setData({ ...data, email_otp: e.target.value })
                    }
                  />
                  <button className="verify-btn" onClick={verifyEmailOtpHandler}>Verify</button>
                </div> */}
              </div>
              <div className="form-group inp-group">
                <div className="input-n-btn">
                  <input
                    type="Number"
                    placeholder="Phone Number"
                    name="phoneNo"
                    className=""
                    maxLength={10}
                    value={data.mobile_number}
                    onChange={(e) =>
                      setData({ ...data, mobile_number: e.target.value })
                    }
                  />
                  <button className="verify-btn" onClick={sendOtpHandler}>Send OTP</button>
                </div>

                <div className="input-n-btn">
                  <input
                    type="Number"
                    placeholder="Mobile OTP"
                    name="verifyOtp"
                    className="px-3"
                    value={data.mobile_otp}
                    onChange={(e) =>
                      setData({ ...data, mobile_otp: e.target.value })
                    }
                  />
                  <button className="verify-btn" onClick={verifyMobileOtpHandler}>Verify</button>
                </div>

                {/* <small className="help-block">{usernameError}</small> */}
              </div>
              {/* <div className="form-group inp-group">
                <input
                  type="Number"
                  placeholder="Mobile OTP"
                  name="verifyOtp"
                  className=""
                  value={data.mobile_otp}
                  onChange={(e) =>
                    setData({ ...data, mobile_otp: e.target.value })
                  }
                />

                <small className="help-block">{usernameError}</small>
              </div> */}
              <div className="form-group inp-group">
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  className=""
                  value={data.password}
                  autoComplete="new-password"
                  onChange={(e) =>
                    setData({ ...data, password: e.target.value })
                  }
                />
                {/* <small className="help-block">{passwordError}</small> */}
              </div>

              <div className="form-group inp-group ">
                <Select
                  showSearch
                  className="w-50"
                  placeholder="Select State"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(stateId) => { getCityListHandler(stateId); setData({ ...data, state_id: stateId }) }}
                >
                  {stateList?.map((state) => (
                    <Option key={state.id} value={state.id}>{state.name}</Option>
                  ))}
                </Select>

                <Select
                  showSearch
                  className="w-50"
                  placeholder="Select City"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => setData({ ...data, city_id: value })}
                >
                  {cityList?.map((city) => (
                    <Option key={city?.id} value={city?.id}>{city?.name}</Option>
                  ))}
                </Select>
                {/* <small className="help-block">{passwordError}</small> */}
              </div>

              <div className="form-group inp-group ">
                <Select
                  showSearch
                  className="w-50"
                  placeholder="Select College"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => setData({ ...data, college_id: value })}
                >
                  {collegeList.map((clg) => (
                    <Option key={clg.id} value={clg.id}>{clg.name}</Option>
                  ))}
                </Select>

                <Select
                  showSearch
                  className="w-50"
                  placeholder="Select Year"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => setData({ ...data, college_year: value })}
                >
                  <Option value="first_year">First Year</Option>
                  <Option value="second_year">Second Year</Option>
                  <Option value="third_year">Third Year</Option>
                  <Option value="fourth_year">Fourth Year</Option>
                  <Option value="fifth_year">Fifth Year</Option>
                </Select>
                {/* <small className="help-block">{passwordError}</small> */}
              </div>

              <div className="submit-slide textAlignCenter">
                <input
                  type="submit"
                  value="Register"
                  className="createButton1"
                  onClick={registerHandler}
                />
              </div>
            </form>
          </div>
        </div>
      </div >
    </div >
  );
}

export default CreateAccount;

// try {
//     const apiData = login({ username, password });
//     console.log(apiData, 'value sakhare')
//     apiData.then((response) => {
//         dispatch({ type: 'success' });
//         props.history.push("/dashboard");
//         console.log(response, 'value sandesh')
//     },
//         (error) => {
//             dispatch({ type: 'error' });
//             console.log(error, 'error')
//         }
//     )
// } catch (error) {
//     alert('wrong')
//     dispatch({ type: 'error' });
// }
